import {Avatar, Box, Card, Container, Grid, makeStyles, Typography} from "@material-ui/core";

import CardContent from '@material-ui/core/CardContent';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import avatar from '../img/avatar.jpeg';

export default function ContactMe() {
    return (
        <div style={{background: "#f0d8fd"}}>
            <Container>
                <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                      spacing={2}
                >
                    <Grid item xs={12} md={12}>
                        <Typography variant={'h2'}> <Box fontWeight="fontWeightBold" m={1}>Contact
                            Me</Box></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    <Grid item xs={12} md={4} sm={6} lg={3}>
                        <ContactMeCard/>
                    </Grid>

                </Grid>
            </Container>
        </div>
    );
}

export function ContactMeCard() {
    const useClasses = makeStyles(theme => ({
        avatar: {
            boxShadow: theme.shadows[5],
            width: 200,
            height: 200,
            alignSelf: "center",
            marginTop: 20
        }
    }))

    return (

        <Card elevation={10} style={{borderRadius: '5%'}}>
            <Grid alignItems={'center'} justifyContent={'center'} container>
                <Avatar alt="Remy Sharp" src={avatar} className={useClasses().avatar}/>
            </Grid>
            <Grid>
                <CardContent>
                    <Typography variant="h4" align={'center'}><Box fontWeight="fontWeightBold" m={1}>
                        Peter Lau</Box>
                    </Typography>
                    <Typography variant="body1" align={'center'}>
                        Business Client Manager
                    </Typography>
                </CardContent>
            </Grid>
            <Grid>
            </Grid>
            <Grid>
                <CardContent>
                    <Grid container>
                        <Grid item xs={2}>
                            <BusinessIcon/>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1" style={{justifyContent: 'center', alignContent: 'center'}}>10/F
                                PCCW Tower, TaiKoo Place, Quarry Bay</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                    <Grid container>
                        <Grid item xs={2}>
                            <PhoneEnabledOutlinedIcon/>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1" style={{justifyContent: 'center', alignContent: 'center'}}>(852)
                                2825 6471</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent>
                    <Grid container>
                        <Grid item xs={2}>
                            <EmailOutlinedIcon/>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1" style={{
                                justifyContent: 'center',
                                alignContent: 'center'
                            }}>peterlau0010@gmail.com</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Grid>
        </Card>
    );
}