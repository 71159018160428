import Introduction from "../component/Introduction";
import AboutMe from "../component/AboutMe";
import Career from "../component/Career";
import Achievement from "../component/Achievement";
import CopyRight from "../component/CopyRight";

export default function MainPage() {
    return (
        <div >
            <Introduction/>
            <AboutMe/>
            <Career/>
            <Achievement/>
            {/*<ContactMe/>*/}
            <CopyRight/>
        </div>);
}

//