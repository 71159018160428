import Wave from "./Wave";
import {Box, Container, Grid, Typography} from "@material-ui/core";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import useWindowDimensions from "../util/Common";
import {SvgIconTypeMap} from "@material-ui/core/SvgIcon/SvgIcon";
import {OverridableComponent} from "@material-ui/core/OverridableComponent";
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

export default function Achievement() {
    const {width} = useWindowDimensions();

    return (
        <div>
            < Wave position={'top'}/>
            <div style={{background: 'black'}}>
                <Container>
                    <Grid container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={2}
                    >
                        <Grid item xs={12}>
                            <Typography variant={`${width > 500 ? 'h2' : 'h4'}`} color={'primary'}> <Box
                                fontWeight="fontWeightBold"
                                m={1}>Achievement</Box></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <br/>
                            <br/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AchievementItem
                                Icon={MonetizationOnOutlinedIcon}
                                title={'Revenue Generation'}
                                description={'Generated $5+ millions revenue in 2020 fiscal year'}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AchievementItem title={'Team Establishment'} Icon={GroupOutlinedIcon}
                                             description={'Formed a 10+ people team in Hong Kong. It contains systems analysts, programmers, business analysts and L2/BAU support members'}/>
                        </Grid>

                        <Grid item xs={12} md={6}>

                            <AchievementItem title={'Project Delivery'} Icon={AssignmentTurnedInOutlinedIcon}
                                             description={'Delivered 20+ projects and enhancements in one year. Improved customer satisfaction, reduced cost and a shorter project duration'}/>
                        </Grid>

                        <Grid item xs={12} md={6}>

                            <AchievementItem title={'Hiring and Recruitment'} Icon={SearchOutlinedIcon}
                                             description={'Recruited 300+ people from Hong Kong and Shenzhen China. Hired 30+ people for the company. They are including but not limited to Mobile Developer, Web Developer, MicroService Developer Project Management Officer, Data Engineer, Data Scientist'}/>
                        </Grid>
                    </Grid>
                </Container>
                < Wave position={'bottom'} color={'purple'}/>
            </div>

        </div>
    );
}

function AchievementItem({
                             title,
                             description,
                             Icon
                         }: { title: string, description: string, Icon: OverridableComponent<SvgIconTypeMap> }) {
    return (
        <Grid container>
            <Grid item xs={2} sm={1} md={3}>
                <Typography color={'primary'}><Icon style={{fontSize: 50}}/></Typography>
            </Grid>
            <Grid item xs={10} sm={11} md={9}>
                <Typography color={'primary'} variant={'h5'}><Box
                    fontWeight="fontWeightBold"
                >{title}</Box></Typography>
                <Typography color={'primary'}>{description}</Typography>
            </Grid>

            <Grid item>
                <MonetizationOnOutlinedIcon style={{fontSize: 100}}/>
            </Grid>
        </Grid>);
}