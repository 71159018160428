import {Box, Container, Grid, Typography} from "@material-ui/core";
import Wave from "./Wave";
import {ContactMeCard} from "./ContactMe";

export default function AboutMe() {
    return (
        <div style={{backgroundColor: "black"}}>
            <Container>
                <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                      spacing={2}
                >
                    <Grid item xs={12} sm={7} md={6}>
                        <Grid item xs={12} md={12}>
                            <Typography variant={'h2'} color={'primary'}> <Box fontWeight="fontWeightBold" m={1}>About
                                Me</Box></Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <br/>
                            <br/>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography color={'primary'}>Peter is seasoned technical consultant with
                                dedicated
                                experience in delivering consulting,
                                solutions and implementation services for banks across the globe, with deep insight
                                of
                                banking
                                domain
                                knowledge in large scale system revamp and end-to-end services.</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <svg width="300" height="300" viewBox="0 0 325 325" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" style={{display: "block",margin: "auto" }}
                            >
                                <path
                                    d="M 100 200 L 250 25 L 325 0 L 300 75 L 125 225 C 150 250 150 275 175 250 C 175 275 200 300 175 300 A 35.5 35.5 90 0 1 150 325 A 125 125 90 0 0 100 250 Q 87.5 247.5 87.5 262.5 T 50 295 T 30 275 T 62.5 237.5 T 75 225 A 125 125 90 0 0 0 175 A 35.5 35.5 90 0 1 25 150 C 25 125 50 150 75 150 C 50 175 75 175 100 200 M 250 25 L 250 75 L 300 75 L 255 70 L 250 25"
                                    fill="#FF5043"/>
                            </svg>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} sm={5} md={6}
                    >
                        <Grid container justifyContent={'center'} alignItems={'center'}>
                            <ContactMeCard/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Wave position={'bottom'}/>
        </div>
    );
}