import {Box, Container, Grid, Typography} from "@material-ui/core";
import poster from '../img/poster.jpeg';
import useWindowDimensions from "../util/Common";

export default function Introduction() {

    const {width} = useWindowDimensions();

    const styles = {
        paperContainer: {
            backgroundImage: `url(${poster})`,
            height: "350px",
        }
    };

    return (
        <div style={styles.paperContainer}>
            <Container style={{paddingTop: 30}}>
                <Grid container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                >
                    <Grid item xs={12}>
                        <Typography align={'left'}>Hi, my name is</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={`${width > 500 ? 'h1' : 'h2'}`} align={'left'}><Box
                            fontWeight="fontWeightBold" m={1}>Peter
                            Lau</Box></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align={'left'}>WORK SMART NOT <label style={{textDecorationLine: 'line-through'}}
                        >HARD</label>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
        ;
}