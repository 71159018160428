import React from "react";
import useWindowDimensions from "../util/Common";

export default function Wave({position, color = 'black'}: { position: string, color?: string }) {
    const {width, scrollbarWidth} = useWindowDimensions();

    switch (position) {
        case 'top':
            return <WaveTop width={width - scrollbarWidth} color={color}/>;
        case 'bottom':
            return <WaveBottom width={width - scrollbarWidth} color={color}/>;
        default:
            return <WaveTop width={width - scrollbarWidth} color={color}/>
    }
}

function WaveTop({width, color}: { width: number, color?: string }) {
    return (<svg className="wave wave-bottom" width={width} height="202" viewBox={`0 0 ${width} 202`} fill="none"
                 xmlns="http://www.w3.org/2000/svg" style={{display: "block",}}>
        <path
            d="M0.0 202V80.4186C0.996094 80.4186 202 -52.0911 743 24.5C1284 101.091 1884 24.5 2624 24.5C3364 24.5 4324 101.091 4324 101.091C4324 101.091 4324 202 4324 202H0.996094Z"
            fill="black"></path>
    </svg>);
}

function WaveBottom({width, color}: { width: number, color?: string }) {
    switch (color) {
        case 'purple':
            return (
                <svg className="wave wave-bottom" width={width} height="250" viewBox={`0 0 ${width} 250`} fill="purple"
                     xmlns="http://www.w3.org/2000/svg" style={{display: "block",}}>
                    <path
                        d="M4323 149.091C4091.5 100.525 3848.5 186.994 3412 202.614C2750.36 226.291 2638.5 40.8657 2124.5 4.04682C1683.02 -27.5776 1127.5 135.797 721 159.38C314.504 182.963 0 128.418 0 128.418V250H4323C4323 250 4323 27.5687 4323 149.091Z"
                        fill="#f0d8fd"/>
                </svg>);
        default:
            return (
                <svg className="wave wave-bottom" width={width} height="250" viewBox={`0 0 ${width} 250`} fill="purple"
                     xmlns="http://www.w3.org/2000/svg" style={{display: "block",}}>
                    <path
                        d="M4323 149.091C4091.5 100.525 3848.5 186.994 3412 202.614C2750.36 226.291 2638.5 40.8657 2124.5 4.04682C1683.02 -27.5776 1127.5 135.797 721 159.38C314.504 182.963 0 128.418 0 128.418V250H4323C4323 250 4323 27.5687 4323 149.091Z"
                        fill="white"/>
                </svg>
            );
    }


}