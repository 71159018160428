import React, {useEffect} from 'react';
import './App.css';
import MainPage from "./page/MainPage";
import {createTheme, ThemeOptions, ThemeProvider} from '@material-ui/core';
import {orange} from "@material-ui/core/colors";

function App() {
    useEffect(() => {
        document.title = "Peter Lau - IBM Business Client Manager"
    }, []);

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
        palette: {
            primary: {
                main: '#fafafa',
            },
            secondary: {
                main: '#009387',
            },
        },
        typography: {
            allVariants: {
                color: "black"
            },
        },
    } as ThemeOptions);

    return (
        <ThemeProvider theme={theme}>
            <MainPage/>
        </ThemeProvider>
    );
}

export default App;
