import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {Box, Container, Grid} from "@material-ui/core";
import useWindowDimensions from "../util/Common";

export default function Career() {
    return (
        <Container>
            <Grid container>
                <Grid item sm={10} style={{textAlign: "right"}}>
                    <Typography variant={'h2'}><Box fontWeight={'bold'} m={'2'}>Career</Box> </Typography>
                </Grid>
                <Grid item sm={12}>
                    <CareerTimeLine/>
                </Grid>
            </Grid>
        </Container>
    )
}

function CareerTimeLine() {
    const {width} = useWindowDimensions();
    return (
        <Timeline align={`${width > 800 ? 'left' : 'left'}`}>
            <CustomTimeLineItem year={'Aug-2020 ~ Present'} shortDescription={'IBM - Business & Client Manager'}
                                longDescription={'Addressing customers\' conerns in a timely manner. Managing challenging situations that may affect customers. Creating a collaborative, innovative and efficient working culture. Negotiating with internal and external stakeholders. Managing a cross-disciplinary team'}
            />
            <CustomTimeLineItem year={'Aug-2019 ~ Jul-2020'} shortDescription={'IBM - Delivery Manager'}
                                longDescription={'Facilitating the timely production of software and other computer products through the effective management of team members and work schedules. Defining and documenting project scope and resource requirements. Setting timelines for deliverables.'}/>
            <CustomTimeLineItem year={'Jan-2019 ~ Jul-2019'}
                                shortDescription={'IBM - Systems Analyst'}
                                longDescription={'Maintaining, upgrading, migrating, designing banking application systems. Collaborating, communicating with clients, users and Business Analysts. Structuring and prioritising business requirements and communicating plans with stakeholders for review and approval'}/>
            <CustomTimeLineItem year={'Aug-2018 ~ Dec-2018'} shortDescription={'IBM - Analyst Programmer'}
                                longDescription={'Analysing , designing, developing and documenting banking application systems, programs and integrations. Including but not limited to Mortgage, Loans, Credit Card projects'}/>
            <CustomTimeLineItem year={'Aug-2017 ~ Jul-2018'} shortDescription={'IBM - Consultant'}
                                longDescription={'Creating, modifying, and maintaining banking application software and other web-based\n' +
                                '                        application. Application domain and technical skill including but not limited to Internet\n' +
                                '                        Banking, Financial Chatbot, Robotic Process Automation (RPA)'}/>
        </Timeline>
    );
}

function CustomTimeLineItem({
                                shortDescription,
                                longDescription,
                                year
                            }: { shortDescription: string, longDescription: string, year: string }) {

    return (<TimelineItem>
        <TimelineOppositeContent style={{display: "none"}}>
            {/*<Typography color="secondary"><Box fontWeight={'bold'}>{shortDescription}</Box></Typography>*/}
        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
        </TimelineSeparator>

        <TimelineContent>
            <Typography color="secondary"><Box fontWeight={'bold'}>{shortDescription} </Box></Typography>
            <Typography variant={'caption'}><Box fontWeight={'bold'}>{year} </Box></Typography>

            <Typography style={{padding: "5px"}}>{longDescription}</Typography>
        </TimelineContent>
    </TimelineItem>);
}